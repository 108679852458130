.pageLeftspace {
    margin-left: 340px;
    padding-top: 30px;
    margin-right: 20px;
    padding-bottom: 10px;
    transition: all 0.5s;
    -webkit-transition:all 0.5s ease; 
    -moz-transition:all 0.5s ease;    
    -o-transition:all 0.5s ease;      
    -ms-transition:all 0.5s ease; 
}
.sidebar-open .pageLeftspace{
    margin-left:20px;
}