.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    background: var(--blackgray);
    padding: 10px 0;
    height: 100vh;
    width:300px;
    transition: transform 0.3s ease-in-out;    
    -webkit-transition:transform 0.3s ease-in-out; 
    -moz-transition:transform 0.3s ease-in-out;    
    -o-transition:transform 0.3s ease-in-out;      
    -ms-transition:transform 0.3s ease-in-out; 
}
.sidebar.CloseMenu{
    transform: translateX(-100%);
}
.logosidebar {
    text-align: center;
    position: relative;
    background:var(--BlueColor);
    margin-top: -10px;
    padding: 12px 0;
}

.logosidebar svg {
    width:54px;
    height:54px;
    margin:auto;
}

.left-sidemneu {
    padding-top:20px;
}
.left-sidemneu ul li a{
color:var(--WhiteColor);
display:flex;
gap:10px;
align-items:center;
font-size:14px;
padding:10px 20px;
border-bottom: solid 1px #4c4b4e;
}
.left-sidemneu ul li a svg{
    fill:var(--WhiteColor);
    height:20px;
    width:20px;
}
.left-sidemneu ul li.active a{
background:var(--orange);
}



@media only screen and (min-width:1920px) {
    .left-sidemneu ul li a svg{
        height:30px;
        width:30px;
    }
}