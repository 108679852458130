.bookform {
    display: flex;
    align-items: end;
    gap: 50px;
    flex-direction: row-reverse;
    width:70%;
    margin: auto;
}
.bookform-left{
    flex:1;
    border: 1px solid #3d3d3d;
    background-color: rgb(41 41 41 / 60%);
    border-radius:20px;
    padding: 30px 30px 35px;
    position: relative;
    backdrop-filter: blur(8px);
}
.bookform-left form {
    display: flex;
    flex-wrap: wrap;
    gap:20px;
}
.form_input {
    flex: 48%;
}
.form_input .rmsc .search input,
.form_input .rmsc .item-renderer {
    display: flex;
    align-items: center;
    color:var(--primary);
}

.form_input .rmsc .dropdown-container:focus-within{
    box-shadow:none;
    border-color:#2e2e2e;
}
.form_input .rmsc .dropdown-container{border:none;background-color: var(--primary);}
.form_input .rmsc .dropdown-heading .dropdown-heading-value{color:var(--white);}

.rmsc .dropdown-heading,
.form_input select,
.inputbox{
    position: relative;
    width: 100%;
    background:var(--primary);
    border:solid 1px #2e2e2e;
    min-height:50px;
    padding: 0 15px;
    border-radius:5px;
    color:var(--white);
    font-size: 14px;
}
.quantity-item span,
.form_input label,
.textarea label {
    margin-bottom: 5px;
    display: block;
    font-size: 14px;
}
.textarea{flex:100%;}
.textarea textarea{
    width: 100%;
    background:var(--primary);
    border:solid 1px #2e2e2e;
    padding:10px 15px;
    border-radius: 5px;
    color:var(--white);
    font-size: 14px;
    resize: none;
}
.form_input .rmsc .gray,
.form_input select::placeholder,
.inputbox::placeholder,
.textarea textarea::placeholder{
    color:#7c7c7c;
    font-weight:600;
}
.form-btn{
    display: flex;
    justify-content: center;
    width: 100%;
}
.form-btn .btn{padding:10px 50px;}
.category-section{
    flex:100%;
}
.category-section h5 {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 14px;
}
.category-section ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.quantity-item {
    width: 20%;
}

@media only screen and (max-width:1199px) {
    .bookform{width:80%;}
}
@media only screen and (max-width:992px) {
    
    .bookform-left form {
        gap: 5px 15px;
        flex-direction:column;
    }
}
@media only screen and (max-width:768px) {
    .bookform-left form {
        gap:15px;
    }
.bookform-left{
    width:100%;
    padding:15px 15px 20px;
}
}