/* .aboutinfoleft img {
    width: 270px;
    height: 270px;
    float: left;
    shape-outside: circle(50%);
    border-radius: 50%;
    margin-right: 40px;
} */
.aboutboxfirstbox {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content:space-between;
}
.aboutinfoleft {
    width:26%;
}
.aboutbox {
    background: #00000059;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
}
.aboutinfocontent h2 strong{font-weight:600;}
.aboutinfocontent h2 {
    margin-bottom: 30px;
    font-size: 35px;
    line-height: 45px;
    font-family:var(--fontPoppins);
}
.aboutinforight {
    width: 60%;
}
.aboutinforight h4{font-size:20px;}
.aboutinfocontent .aboutinforight h3{
font-size:40px;
margin:20px 0 30px 0;
}
.aboutinfocontent h3 {
    margin:0 0 20px 0;
    font-size: 25px;
    line-height: 35px;
    font-weight: 500;
    font-family: var(--fontPoppins);
}
.aboutinfocontent ul li strong,
.aboutinfocontent p strong{color:var(--white); font-weight:500;}

.aboutinfocontent p {
    margin-bottom: 20px;
    text-align: justify;
    color: #999898;
    font-family: var(--fontPoppins);
   
}
.aboutinfocontent p:last-child{margin-bottom:0;}
.aboutinfocontent ul{margin-bottom:30px;}
.aboutinfocontent ul li {
    margin-bottom:10px;
    list-style: disc;
    margin-left: 25px;
    color: #999898;
    text-align:justify;
    font-family: var(--fontPoppins);
}

@media only screen and (max-width:1199px) {
    .aboutinforight {
        width: 68%;
    }
}

@media only screen and (max-width:768px) {
    .aboutboxfirstbox{
        flex-direction: column-reverse;
    }
    .aboutinfocontent .aboutinforight p{text-align:center;}
    .aboutinforight {
        width:100%;
        text-align:center;
    }
    .aboutinfoleft {
        width:46%
    }   
    .aboutinfocontent p{line-height:24px;}
    .aboutinfocontent .aboutinforight h3 {
        font-size: 30px;
        margin: 20px 0;
    }
}