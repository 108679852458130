.header-top{
    background: var(--blackgray);
    padding: 22px;
    margin-left: 299px;
}
.sidebar-open .header-top{
    margin-left:0;
}
.page-title{
    color:var(--WhiteColor);
    gap:20px;
}
.page-title svg path{
fill:var(--WhiteColor);
}
.user{
    display:flex;
    gap:20px;
}
.user svg{
    width:35px;
    height:35px;
}
.logout{
    background:var(--WhiteColor);
    border: none;
    padding: 0 5px;
    border-radius: 5px;
}
.logout svg {
    width: 27px;
    height: 27px;
}