.contactpage.preorder::after{
display:none;
}

.contactinfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    gap: 3px;
    border-bottom: solid 1px #2e2e2e;
    padding-bottom: 10px;
}
.contactinfo:last-child{margin-bottom:0;}
.contactinfo span {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
}
.contactinfo span svg{width:25px; height:25px;}
.contactmain {
    display: flex;
    gap:50px;
}
.information {
    width:40%;
    border: 1px solid #3d3d3d;
    background-color: rgb(41 41 41 / 60%);
    border-radius: 20px;
    padding: 30px 30px 35px;
    position: relative;
    backdrop-filter: blur(8px);
}
.title h2 {
    font-size: 30px;
    margin-bottom: 30px;
    font-weight:bold;
    color:var(--orange);
}
.instagram{margin-top:5px;}
.leftspace {
    margin-left: 40px;
}
@media only screen and (max-width:1199px) {
    .information {
        padding:20px;
        width: 50%;
    }
}
@media only screen and (max-width:992px) {
    .contactmain{gap:20px;}
    .information{width:70%;}
    .title h2 {
        font-size: 25px;
    }
}
    
@media only screen and (max-width:768px) {
    .contactmain{flex-direction:column-reverse;gap:30px;}
    .information,
    .bookform{width:100%;}
    .information{padding:20px;}
    .title h2{font-size:20px;margin-bottom:25px;}
}