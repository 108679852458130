.form-fields {
    background: var(--blackgray);
    padding: 30px;
    border-radius: 20px;
    box-shadow:0 0 10px 5px #00000030;
    margin-bottom: 1rem;
    gap:20px;
    display:flex;
    justify-content: space-between;
    flex-direction:column;
}
.DescriptionCategory label,
.grid-item{position: relative;}
.grid-item label{margin-bottom:5px;}
.form-control:focus,
.form-control{color:var(--WhiteColor) !important; background:var(--primary)!important; border-color:#525252 !important;
  font-size:14px !important;
  padding:10px !important;
  color-scheme: dark;
}
.form-control::placeholder{
  color:#9f9f9f!important;
  font-weight:600;
}
/* input[type="date"],
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"],
input[type="time"]::-webkit-calendar-picker-indicator{
  background-color:#fff;
  } */
  .custom-select svg path{fill:var(--WhiteColor);}
  .custom-select svg {
    position: absolute;
    right:15px;
    top: 40px;
    transform: rotate(-90deg);
    pointer-events: none;    
    z-index: 1;
}

.grid-container {
  display: flex;
  gap:8px;
  justify-content: space-between;
  align-items:end;
}
.grid-item{width:100%;}
.DescriptionCategory{
  width:100%;
}
.DescriptionCategory .form-control{
  height:47px;
  resize: none;
}
  .menuitemadd{position: relative;
    display:flex;
    flex-direction: column;
    gap: 20px;
      }
  .menuaddbtn {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--YellowColor);
    border-radius: 0 4px 4px 0;
    border: none;
    height: 42px;
    width: 42px;
    color:var(--BlueColor);
}
  .menuaddbtn svg {    
    fill:var(--BlueColor);
    width:20px;
    height:20px;   
}
  /* Submit button alignment */

.ql-container{height:70%;}
.menuItems {
  display: flex;
  gap: 15px;
  flex-wrap:wrap;
}
.menuItems ul li{
  display:flex; align-items:center;  
}
.dishname {
  background: #404040;
  color: var(--WhiteColor);
  padding: 15px;
  border-radius: 5px;
  position: relative;
  width:32%;
  border: solid 1px #686868;
}
.ragulardish .description{
  padding-left: 30px;
  display: flex;
  flex-direction: column;
}
.description {
  border-top:dashed 1px var(--orange);
  padding-top: 10px;
  margin-top: 10px;
}

.dishname span{display:flex; align-items:center;  
  gap:10px; white-space:nowrap;
  font-weight: 500;
  font-size: 15px;
}
.gallerypicadd .icon-close,
.dishname .icon-close {
  display: flex;
  align-items: center;
  justify-content: center; 
  width: 25px;
  height: 25px;
  z-index:2;
  cursor: pointer;
  fill: var(--blackgray);
  position: absolute;
  right: -8px;
  top: -4px;
  background: var(--secondary);
  border-radius: 50%;
  padding: 3px;
}
.dishname span input {
  width: 18px;
  height: 18px;  
}
.dishname h4{
  margin-bottom:0;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.textariabox *{margin:0;}
.mainmenudish .rmsc .dropdown-content{z-index:9;}
.mainmenudish .rmsc .search input,
.mainmenudish .rmsc .options{background:var(--primary);}
.mainmenudish .rmsc .select-item:hover, 
.mainmenudish .rmsc .select-item.selected {
  background: var(--blackgray);
}
.mainmenudish .rmsc .dropdown-heading, .mainmenudish .form_input select, .mainmenudish .inputbox{min-height:40px;}
.mainmenudish .rmsc .dropdown-container,
.mainmenudish .rmsc.multi-select{border-color:#505050; background:transparent;}
.mainmenudish .rmsc .select-item {
  padding: 5px 15px;  
}

/* mainmenudish  css*/
.grid-container.mainmenudish{flex-wrap:wrap;
gap:20px 15px;}
.mainmenudish .grid-item {
  width:32%;
}
.mainmenudish .grid-item.priceinput {
  width:15%;
}
.grid-item.iconsinner {
  display: flex;
  flex-direction: column;
}
.iconsinner span{display: flex; gap:10px; align-items:center; cursor: pointer;}
.iconsinner img,
.iconsinner svg{width:30px; height:30px;}

.iconsinner input[type="checkbox"] {
  width: 20px;
  height: 20px;
  position: absolute;
  opacity: 0;
}

.iconsinner .icon-checkbox {
  display: inline-block;
  width:20px;
  height:20px;
  border:1px solid var(--YellowColor);
  border-radius:5px;
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.iconsinner .icon-checkbox::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--YellowColor);
  border-radius: 5px;
  transform: scale(0); /* Default scale */
  transition: all 0.3s ease-in-out; /* Smooth animation */
  opacity: 0.7;
}
.iconsinner .icon-checkbox.checked::after {
  transform: scale(0.7); /* Scale effect for checked state */
  opacity: 1;
}

/* card css */


.mainmenudish .grid-item.innerselectitems {
  display: flex;
  gap: 0 15px;
  width:auto;
}
.iconsinner {
  width:auto;
}

.dishname h4 label.icon-checkbox {
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  border: solid 2px var(--WhiteColor);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s linear;
}

.dishname h4 label.icon-checkbox::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--YellowColor);
  transform: scale(0);
  border-radius: 4px;
  opacity: 0.7;
  transition: all 0.5s linear;
}

.dishname input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.dishname input[type="checkbox"]:checked ~ h4 label.icon-checkbox::after {
  transform:scale(0.7);
}

.dishname input[type="checkbox"]:checked ~ h4 label.icon-checkbox {
  border-color: var(--YellowColor);
}
.cardicons{display:flex; gap:10px;
width:100%; margin-top:15px;}
.cardicons svg{
width:20px;
height:20px;
}

.cardicons img{
  position: absolute;
  top: 56px;
  left: 10px;
}
.ragulardishname h3{font-size:18px;
  margin-bottom:5px;
  color:var(--YellowColor); 
  font-weight:500; margin:0;}
.ragulardishname span{
  display: flex  ;
  align-items: center;
  white-space: nowrap;
  font-size:16px;
  margin:5px 0 10px 0;
}
.textariabox{font-size:14px;}
.active.dishname {
  background: #141414;
}

@media only screen and (min-width:1920px) {
  .form-fields{
    gap:50px;
  }
  .mainmenudish .grid-item.priceinput {
    width: 21%;
}
  .mainmenudish .grid-item {
    width: 24%;
  }
  .mainmenudish .grid-item.innerselectitems {   
    width:auto;
}
  .dishname {  
    width:24%;
  }
  .ragulardish .dishname {  
    width:32.5%;
  }
  .grid-container{flex-wrap:nowrap; gap:20px;}
}

@media only screen and (max-width:1199px) {
  .dishname {  
    width:48%;
  }
  .grid-container{flex-wrap:wrap; justify-content: center;}
  .grid-item {
    width: 48%;
}
.mainmenudish .grid-item {
  width: 48%;
}
.mainmenudish .grid-item.priceinput {
  width: 23%;
}

}

@media only screen and (max-width:992px) {
  .mainmenudish .grid-item.priceinput {
    width: 48%;
}
.grid-item label {
  margin-bottom: 2px;
  font-size: 13px;
}
.iconsinner input[type="checkbox"] {
  width: 16px;
  height: 16px;
}
.textariabox {
  font-size: 12px;
  margin-right:10px;
}
.textariabox:last-child{margin-right:0;}

}