.menulist:first-child .menutitle{margin-top:0;}
.menutitle {
    margin-top:40px;
    text-align: center;
    background: rgb(86 86 86 / 38%);
    padding:10px;
    border-radius: 10px;
}

.menutitle h3{font-size:35px; line-height:45px; font-family:var(--fontAmita); color:var(--orange);}
.menutitle p{margin-top:10px; font-weight:500; font-family:var(--fontPoppins);}
.menutitle.Nonvage {
    background:transparent;
    padding:0;    
}
.categary-name {
    display: flex;
    flex-wrap: wrap;
    border:solid 1px #626262;
    margin-top:30px;
}
.categary-name li {
    border-bottom:solid 1px #626262;
    border-right:solid 1px #626262;
    padding: 10px;
    width: 25%;
}

.pagination{justify-content:center;}
.btnpagination {
    border: solid 1px #3e3e3e;
    padding: 3px 12px;
    background: var(--primary);
    line-height: 12px;
}
.btnpagination svg{width:20px; height:20px;}
.btnpagination svg path{
    fill:var(--white);
}
.btnpagination[disabled]{background:#5a5a5a;}
.btnpagination.nextbtn{padding:5px;}
.btnpagination.prevtbtn{
    padding:5px;
    transform:rotate(180deg);
}
.btnpagination.active{
    background:var(--orange);
}

@media only screen and (min-width:1920px) {
    .menutitle h3{font-size:40px; line-height:50px;}
.menutitle{
padding:20px;
}
}

@media only screen and (max-width:992px) {
.menutitle h3 {
font-size: 30px;
line-height: 40px;
}    
.categary-name li{width:33.3%;}
}


@media only screen and (max-width:768px) {  
    .menutitle p{font-size:12px;margin-top:5px;}
    .menutitle{padding:10px;margin-top:30px;}
    .categary-name li{width:100%;padding:6px;}
    .menutitle h3 {
        font-size: 25px;
        line-height: 30px;
    }


}