.locationform {
  display: flex;
  width: 100%;
  gap:15px;
  align-items: end;
  flex-direction:column;
}
.locationform .form-group {
  width: 50%;
}

.locationform .form-group.locationsearch{width:100%;}
.showLocation {
    position: relative;
    width: 100%;
    /* padding-top:30.25%; */
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .showLocation iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }