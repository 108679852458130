.menu-main{
    display: flex;
    flex-wrap: wrap;
    gap: 50px 100px;
}
.menubar.menubar02{margin-left:32px;}
.menubar {
    flex: 1;
}
.menubox{width:100%; margin-bottom:60px;}
.menubox:last-child{margin-bottom:0;}
.maintitle{
    border-bottom: solid 1px var(--orange);
    margin-bottom:30px;
    padding-bottom:5px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.maintitle::after{
    content: "";
    position: absolute;
    bottom:-10px;
    left:0;
    width: 100%;
    height: 1px;
    border-bottom:dotted 2px var(--orange);    
}
.maintitle h5 {
    color: var(--orange);
    font-size: 30px;
    line-height: 38px;
    font-family: var(--fontAmita);
    font-weight: 600;    
    position: relative;    
}
.maintitle span{    
    color: var(--green);
    font-size:14px  
}

.itemname {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.itemname figcaption{display:flex; gap:10px; align-items:center;}
.itemname figcaption svg {
    width: 20px;
    height: 30px;
}

.menucategory {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap:5px;
}
.menucategory:last-child{margin-bottom:0;}
.menucategory p{
    font-size:14px;
    line-height:20px;
    color:#8f8f8f;
    font-family:var(--fontfamily);
    font-weight: 500;
    text-align:justify;
}
.categaryname {
    display: flex;
    gap:5px 10px;
    align-items: center;
}
.categaryname.categaryname02 {
    flex-direction: column;
    align-items: baseline;
}
.categaryname h6 {
    font-family: var(--fontAmita);
    font-size: 18px;
    line-height: 24px;
    color: var(--secondary);
    position: relative;
}
.categaryname h6::before{
    content: "";
    position: absolute;
    left: -20px; 
    width:5px;
    height:5px;
    background:var(--orange);    
    border-radius:50%;
    top:8px;
}
.favrate.categaryname h6::before{
    background:transparent url(../../../public/images/cap.svg) no-repeat center !important;     
    width: 30px;
    height: 30px;
    left:-38px;
    top: -5px;    
    border-radius:0;
}
.itemname span {
    font-family: var(--fontAmita);
    color: var(--orange);
    font-size: 20px;
    margin-left:8px;
}

.categaryname sup {
    font-family: var(--fontPoppins);
    line-height: 14px;
    color: var(--green);
    font-weight:600;
}
.categaryname label sup{margin-left:10px;}
.categaryname label{
    font-family: var(--fontAmita);  
    color: var(--secondary);
    margin-top: 3px;
}

@media only screen and (min-width:1920px) {
    .menucategory{gap:10px;}
    .categaryname h6 {
        font-size: 22px;
        line-height:32px;
    }
    .categaryname h6::before{
        left: -35px;
        top: 12px;
    }
    .favrate.categaryname h6::before{
        left:-45px;
        top:0px;
    }

}


@media only screen and (max-width:992px) {
    .menu-main{
        flex-direction:column;
        width: 80%;
        margin: auto;
    }
    .menubar {
        margin-left: 32px;
    }
    .menubox{margin-bottom:40px;}
    .menucategory p {
        font-size: 12px;
        line-height: 18px;
    }
    .categaryname label{margin-top:0;}
}

@media only screen and (max-width:768px) {
    .maintitle span{font-size:12px;}
    .menu-main{width:100%;}
    .categaryname{gap:5px;}    
    .maintitle h5::before{left:-20px;}
    .categaryname h6 {
        font-size:16px;
        line-height:24px;        
    }
    .menubar {
        margin-left: 20px;
    }
    .favrate.categaryname h6::before{left:-35px;width:25px;height: 18px; top:2px;}
    .categaryname h6::before{left:-20px;}
    .itemname span {
        font-size: 14px;
        margin-left: 5px;
    }
    .itemname figcaption{gap:5px;}
    .categaryname label sup {
        margin-left: 5px;
    }
}